// import external modules
import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from './app/app';

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import {store} from "./redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./comps/Spinner";
import {BrowserRouter, Switch} from "react-router-dom";

//const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Suspense fallback={<Spinner/>}>
        <App/>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick/>
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

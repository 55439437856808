import {clearToken, fetchGet, fetchPost, saveToken} from "../commons";

const requestLoginAction = "REQUEST_LOGIN_ACTION";
const responseLoginAction = "RESPONSE_LOGIN_ACTION";
const requestMeAction = "REQUEST_ME";
const responseMeAction = "RESPONSE_ME";
const requestSignOutAction = "REQUEST_SIGNOUT";
const responseSignOutAction = "RESPONSE_SIGNOUT";


const initialState = {
  loggingIn: false,
  loginResponse: null,
  loadingMe: false,
  me: null
};

export const actionCreators = {
  signIn: (username, password, callback) => async (dispatch, getState) => {
    dispatch({type: requestLoginAction});
    const response = await fetchPost("/api/users/signin", {username, password});
    if (response && response.errorNo === 0) {
      saveToken(response.message);
      dispatch(actionCreators.getCurrentUser());
      if (callback) callback();
    }
    dispatch({type: responseLoginAction, response});

  },
  getCurrentUser: (callback) => async (dispatch, getState) => {
    dispatch({type: requestMeAction});
    try {
      const response = await fetchGet("/api/users/me");
      dispatch({type: responseMeAction, response});
    } catch (ex) {
      if (ex === "Unauthorized" && callback) callback();
      dispatch({type: responseMeAction});
    }
  },
  signOut: (successCallback) => async (dispatch, getState) => {
    dispatch({type: requestSignOutAction});
    clearToken();
    dispatch({type: responseSignOutAction});
    if (successCallback) successCallback();
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestLoginAction:
      return {...state, loggingIn: true, loginResponse: null};
    case responseLoginAction:
      return {...state, loggingIn: false, loginResponse: action.response};
    case requestMeAction:
      return {...state, loadingMe: true, me: null};
    case responseMeAction:
      return {...state, loadingMe: false, me: action.response};
    case requestSignOutAction:
      return initialState;
    default:
      return state;
  }
};

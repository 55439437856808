import React, {useState} from "react";
import {Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown} from "reactstrap";
import {LogOut, Menu, MoreVertical} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import {actionCreators} from '../../../redux/Authentication';
import {useHistory} from "react-router-dom";

function ThemeNavbar(props) {
  const handleClick = e => {
    props.toggleSidebarMenu("open");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(actionCreators.signOut(() => history.replace('/signin')));
  }

  return (
    <Navbar className="navbar navbar-expand-lg navbar-light bg-faded">
      <div className="container-fluid px-0">
        <div className="navbar-header">
          <Menu
            size={14}
            className="navbar-toggle d-lg-none float-left"
            onClick={handleClick}
            data-toggle="collapse"
          />

          <MoreVertical
            className="mt-1 navbar-toggler black no-border float-right"
            size={50}
            onClick={toggle}
          />
        </div>

        <div className="navbar-container">
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto float-right" navbar>
              {auth.me && auth.me.username && <UncontrolledDropdown nav inNavbar className="pr-1">
                <DropdownToggle nav>
                  <span>{auth.me.username}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <a className="p-0" onClick={handleLogout}>
                    <DropdownItem>
                      <LogOut size={16} className="mr-1"/> Logout
                    </DropdownItem>
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>}

            </Nav>
          </Collapse>
        </div>
      </div>
    </Navbar>
  );
}

export default ThemeNavbar;

import {combineReducers} from "redux";
import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
import chatReducer from "./chat/";
import contactsReducer from "./contacts/";
import todoReducer from "./todo/";
import customizer from "./customizer/";

import {reducer as toastrReducer} from "react-redux-toastr";
import {reducer as authReducer} from '../Authentication';
import {reducer as dataReducer} from '../Data';

const rootReducer = combineReducers({
  calender: calenderReducer,
  emailApp: emailReducer,
  contactApp: contactsReducer,
  todoApp: todoReducer,
  toastr: toastrReducer, // <- Mounted at toastr.
  chatApp: chatReducer,
  customizer: customizer,
  auth: authReducer,
  data: dataReducer,
});

export default rootReducer;

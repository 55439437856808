import React, {useEffect} from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import Router from "./router";
import {useDispatch, useSelector} from "react-redux";
import {actionCreators} from '../redux/Authentication';
import SpinnerComponent from "../comps/Spinner";
import {useHistory} from "react-router-dom";

function App(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const history = useHistory();

  useEffect(() => {
    dispatch(actionCreators.getCurrentUser(() => history.push('/signin')));
  }, []);

  if (auth.loadingMe) return <SpinnerComponent/>
  else return (<Router/>);
}

export default App;

import { downloadCsvPost, fetchPost } from "../commons";

export const requestBetTransaction = "REQUEST_BET_TRANSACTION";
export const responseBetTransaction = "RESPONSE_BET_TRANSACTION";
export const requestChangeBetTxCurrentPage = "CHANGE_CURRENT_PAGE_BET_TX";
export const requestResetBetTx = "RESET_BET_TX";
export const requestBetTxCsvExport = "REQUEST_BET_TX_CSV_EXPORT";
export const responseBetTxCsvExport = "RESPONSE_BET_TX_CSV_EXPORT";

export const requestFinanceTransaction = "REQUEST_FINANCE_TRANSACTION";
export const responseFinanceTransaction = "RESPONSE_FINANCE_TRANSACTION";
export const requestResetFinanceTx = "REQUEST_RESET_FINANCE_TX";
export const requestChangeFinanceTxCurrentPage = "CHANGE_CURRENT_PAGE_FINANCE_TX";
export const requestFinanceTxCsvExport = "REQUEST_FINANCE_TX_CSV_EXPORT";
export const responseFinanceTxCsvExport = "RESPONSE_FINANCE_TX_CSV_EXPORT";


const initialState = {
  searchingBetTransaction: false,
  betTx: [],
  betTxCurrentPage: 1,
  betTxTotalPage: 1,
  betTxCsvLoading: false,
  searchingFinanceTransaction: false,
  financeTransactions: [],
  financeTxCurrentPage: 1,
  financeTxTotalPage: 1,
  financeTxCsvLoading: false
};

export const actionCreators = {
  searchBetTransaction: (values) => async (dispatch, getState) => {
    dispatch({ type: requestBetTransaction });
    const copy = { ...values };
    for (let key of Object.keys(copy)) {
      if (!copy[key]) copy[key] = null;
    }
    const response = await fetchPost("/api/data/bets", {
      ...copy,
      page: getState().data.betTxCurrentPage,
    });
    dispatch({ type: responseBetTransaction, response });
  },

  changeBetTxCurrentPage: (values, page) => async (dispatch, getState) => {
    if (page === getState().data.betTxCurrentPage) return;
    dispatch({ type: requestChangeBetTxCurrentPage, page });
    dispatch(actionCreators.searchBetTransaction(values));
  },

  resetBetTx: () => async (dispatch, getState) => {
    dispatch({ type: requestResetBetTx });
  },

  resetFinanceTx: () => async (dispatch, getState) => {
    dispatch({ type: requestResetFinanceTx });
  },

  betTxCsvExport: (values) => async (dispatch, getState) => {
    const copy = { ...values };
    for (let key of Object.keys(copy)) {
      if (!copy[key]) copy[key] = null;
    }
    dispatch({ type: requestBetTxCsvExport });
    await downloadCsvPost('/api/data/bets/export', 'bettx.csv', copy);
    dispatch({ type: responseBetTxCsvExport });
  },

  searchFinancialTransaction: (values) => async (dispatch, getState) => {
    dispatch({ type: requestFinanceTransaction });

    const copy = { ...values };
    for (let key of Object.keys(copy)) {
      if (!copy[key]) copy[key] = null;
    }
    const response = await fetchPost("/api/data/finance", {
      ...copy,
      page: getState().data.financeTxCurrentPage,
    });
    dispatch({ type: responseFinanceTransaction, response });
  },

  changeFinanceTxCurrentPage: (values, page) => async (dispatch, getState) => {
    if (page === getState().data.financeTxCurrentPage) return;
    dispatch({ type: requestChangeFinanceTxCurrentPage, page });
    dispatch(actionCreators.searchFinancialTransaction(values));
  },

  financeTxCsvExport: (values) => async (dispatch, getState) => {
    const copy = { ...values };
    for (let key of Object.keys(copy)) {
      if (!copy[key]) copy[key] = null;
    }
    dispatch({ type: requestFinanceTxCsvExport });
    await downloadCsvPost('/api/data/finance/export', 'financetx.csv', copy);
    dispatch({ type: responseFinanceTxCsvExport });
  },

};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestBetTransaction:
      return { ...state, searchingBetTransaction: true };
    case responseBetTransaction:
      return {
        ...state,
        searchingBetTransaction: false,
        betTx: action.response.data,
        betTxTotalPage: Number.parseInt(action.response.identity)
      };
    case requestChangeBetTxCurrentPage:
      return { ...state, betTxCurrentPage: action.page };
    case requestFinanceTransaction:
      return { ...state, searchingFinanceTransaction: true, financeTransactions: null };
    case responseFinanceTransaction:
      return {
        ...state, searchingFinanceTransaction: false, financeTransactions: action.response.data,
        financeTxTotalPage: Number.parseInt(action.response.identity)
      };
    case requestResetBetTx:
      return { ...state, betTxCurrentPage: 1, betTx: [], betTxTotalPage: 1 };
    case requestResetFinanceTx:
      return { ...state, financeTxCurrentPage: 1, financeTransactions: [], financeTxTotalPage: 1 };
    case requestBetTxCsvExport:
      return { ...state, betTxCsvLoading: true };
    case responseBetTxCsvExport:
      return { ...state, betTxCsvLoading: false };
    case requestChangeFinanceTxCurrentPage:
      return { ...state, financeTxCurrentPage: action.page };
    case requestFinanceTxCsvExport:
      return { ...state, financeTxCsvLoading: true };
    case responseFinanceTxCsvExport:
      return { ...state, financeTxCsvLoading: false };
    default:
      return state;
  }
};

import React, {Component} from "react";

import {MessageSquare, Home, List, Users, DollarSign} from "react-feather";
import {NavLink} from "react-router-dom";
import "../../../../assets/scss/components/sidebar/sidemenu/sidemenu.scss";
import SideMenu from "../sidemenuHelper";

class SideMenuContent extends Component {
  render() {
    return (
      <SideMenu className="sidebar-content" toggleSidebarMenu={this.props.toggleSidebarMenu}>
        <SideMenu.MenuSingleItem>
          <NavLink exact to="/" activeClassName="active">
            <i className="menu-icon">
              <Home size={18}/>
            </i>
            <span className="menu-item-text">Home</span>
          </NavLink>
        </SideMenu.MenuSingleItem>

        <SideMenu.MenuSingleItem>
          <NavLink to="/bets" activeClassName="active">
            <i className="menu-icon">
              <List size={18}/>
            </i>
            <span className="menu-item-text">Bet Transactions</span>
          </NavLink>
        </SideMenu.MenuSingleItem>

        <SideMenu.MenuSingleItem>
          <NavLink to="/transactions" activeClassName="active">
            <i className="menu-icon">
              <DollarSign size={18}/>
            </i>
            <span className="menu-item-text">Financial Transactions</span>
          </NavLink>
        </SideMenu.MenuSingleItem>

        {/* <SideMenu.MenuSingleItem>
          <NavLink to="/exclusions" activeClassName="active">
            <i className="menu-icon">
              <Users size={18}/>
            </i>
            <span className="menu-item-text">Exclusion list</span>
          </NavLink>
        </SideMenu.MenuSingleItem> */}
      </SideMenu>
    );
  }
}

export default SideMenuContent;

import config from "./config";

export const apiUrl = config.API_DOMAIN;

export const saveToken = token => localStorage.setItem("token", token);
export const getToken = () => localStorage.getItem("token");
export const clearToken = () => localStorage.removeItem("token");

export const unauthorizedRoutes = ["signin"];

export const handleUnauthorized = () => {
  //clearToken();
  if(document && document.location && !unauthorizedRoutes.includes(document.location.pathname.replace('/',''))) {
    document.location.href = "/signin";
  }
}

export const fetchPost = async (url, body) => {
  const fetchTask = await fetch(url, {
    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()},
    method: 'POST',
    body: JSON.stringify(body)
  });
  if (fetchTask.status === 401) handleUnauthorized();
  return await fetchTask.json();
};

export const fetchGet = async (url) => {
  const fetchTask = await fetch(url, {
    headers: {'Authorization': 'Bearer ' + getToken()},
    mode: 'cors'
  });
  if (fetchTask.status === 401) handleUnauthorized();
  return await fetchTask.json();
};

export const downloadCsv = async (url, fileName) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(r => r.blob()).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch(console.log);
}

export const downloadCsvPost = async (url, fileName, postData) => {
  if (!window || !window.document) {
    return console.error("Download called outside browser");
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    body: JSON.stringify(postData)
  }).then(r => r.blob()).then(response => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch(console.log);
}

import React, {Component, lazy, Suspense} from "react";
import {Switch} from "react-router-dom";
import Spinner from "../comps/Spinner";
import MainLayoutRoutes from "../layouts/routes/mainRoutes";
import FullPageLayout from "../layouts/routes/fullpageRoutes";
import ErrorLayoutRoute from "../layouts/routes/errorRoutes";

const LazyLogin = lazy(() => import("../comps/SignIn"));
const LazyHomePage = lazy(() => import("../comps/HomePage"));
const LazyBetLogs = lazy(() => import("../comps/BetLogs"));
const LazyTransactionLogs = lazy(() => import("../comps/TransactionLogs"));
const LazyExclusions = lazy(() => import("../comps/Exclusions"));
const LazyErrorPage = lazy(() => import("../views/pages/error"));

class Router extends Component {
  render() {
    return (
      <Switch>
        <FullPageLayout
          exact
          path="/signin"
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyLogin {...matchprops} />
            </Suspense>
          )}
        />

        <MainLayoutRoutes
          exact
          path="/"
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyHomePage {...matchprops} />
            </Suspense>
          )}
        />

        <MainLayoutRoutes
          exact
          path="/bets"
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyBetLogs {...matchprops} />
            </Suspense>
          )}
        />

        <MainLayoutRoutes
          exact
          path="/transactions"
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyTransactionLogs {...matchprops} />
            </Suspense>
          )}
        />

        <MainLayoutRoutes
          exact
          path="/exclusions"
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyExclusions {...matchprops} />
            </Suspense>
          )}
        />

        <ErrorLayoutRoute
          render={matchprops => (
            <Suspense fallback={<Spinner/>}>
              <LazyErrorPage {...matchprops} />
            </Suspense>
          )}
        />
      </Switch>
    );
  }
}

export default Router;
